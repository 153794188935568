import React from "react";
import { getObject, isBrowser } from "../../utils";

import parse from "html-react-parser";


const MobileImageAds = ({ index, isMobile, dataURL, imageAdsInserted, currentPage, article }) => { 
	
  const options = {
    replace: (node) => {
      // If on desktop do not change node
      if (!isBrowser() || !isMobile || imageAdsInserted) {
        return;
      }

      // If there is no node then do not change node
      if (!node.attribs) {
        return;
      }

      // If there is an image wrapped in gatsby-image-wrapper change node and set new image tag
      if (
        node.attribs &&
        node.attribs.class &&
        node.attribs.class.indexOf("gatsby-image-wrapper") !== -1
      ) {
        const picture = getObject(node.children, "name", "picture");
        const image = getObject(picture.children, "name", "img");
        const src = image.attribs["data-src"];
        const srcSet = image.attribs["data-srcset"];
        const sizes = image.attribs["sizes"];

        imageAdsInserted = true;

        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="LeaderBelowTitle" className="ad-container" />
            </div>
            <img src={src} sizes={sizes} srcSet={srcSet} alt="" />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
              <div id="MPUTopRight" className="ad-container" />
            </div>
          </>
        );
      }

      // If on mobile and node has an image
      if (node.attribs && node.attribs.src) {
        imageAdsInserted = true;
        return (
          <>
            <div className="code-block code-block--banner">
              <div className="divider">
                <span>Advertisement</span>
              </div>
							<div id="LeaderBelowTitle" className="ad-container ad-container--leaderBelowTitle" /> 
            </div>
            <img class="code-block__image" src={node.attribs.src} alt="" />
            <div className="code-block code-block--banner">
              <div className="divider">
                <span> Advertisement</span>
              </div>
              <div id="MPUTopRight" className="ad-container ad-container--MPUTopRight" />
            </div>
          </>
        );
      }

      // If all else Fails then don't change node
      return;
    },
    trim: true,
  };

  return (
		<div data-url={dataURL} data-index={index} style={{display: index === currentPage ? "block" : "none"}}>
			{parse(article, options)}
		</div>
	);
};

export default MobileImageAds;
